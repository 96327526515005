import { Grid, Typography } from '@mui/material'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import backgroundImage from '../../images/Path 845.png'
import { LoginFooterCon } from './skins'
import twitterIcon from '../../images/Path 846.png'
import facebookIcon from '../../images/Path 847.png'

//Imports for the display of legal documentation in PDF format
import ModalWrapper from 'components/ModalWrapper'
import { useQuery } from 'react-query'
import { getLegalDocument } from 'api/legalDocument'

//Imports for the display of legal documentation in HTML format
// import AdsModal from 'components/AdsModal'
import HelpModal from 'components/HelpModal'

const LoginFooter = () => {
	//Variables to handle rendering of legal documents in PDF
	const [isVisiblePrivacy, setIsVisiblePrivacy] = useState(false)
	const [isVisibleCookies, setIsVisibleCookies] = useState(false)
	const [isVisibleConditions, setIsVisibleConditions] = useState(false)

	// const [adsModalVisible, setAdsModalVisible] = useState(false)
	const [helpModalVisible, setHelpModalVisible] = useState(false)

	const { t } = useTranslation()

	const buttonStyle = {
		marginLeft: '2%',
		cursor: 'pointer',
		backgroundColor: 'transparent',
		border: 'none',
		padding: 0,
		fontFamily: 'Segoe UI'
	}

	// retrieve the standard Privacy Notice
	const { isFetching: isFetchingPrivacyNotice, data: PrivacyNoticeFile } =
		useQuery(['legal-documentation'], () => getLegalDocument('PrivacyNotice'), {
			staleTime: 'Infinity',
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: false,
			enabled: isVisiblePrivacy
		})

	// retrieve the Cookies Notice
	const { isFetching: isFetchingCookiesNotice, data: CookiesNoticeFile } =
		useQuery(['legal-documentation'], () => getLegalDocument('CookiesNotice'), {
			staleTime: 'Infinity',
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: false,
			enabled: isVisibleCookies
		})

	// retrieve the Conditions of use & sale
	const { isFetching: isFetchingConditions, data: ConditionsFile } = useQuery(
		['legal-documentation'],
		() => getLegalDocument('ConditionsOfUse'),
		{
			staleTime: 'Infinity',
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: false,
			enabled: isVisibleConditions
		}
	)

	return (
		<LoginFooterCon background={backgroundImage} id="main-footer">
			<Grid container columns={12} spacing={2}>
				<Grid item xs={10} sm={10} lg={10} xl={10} className="links-container">
					{/* Conditions */}
					<button
						className="link-item h8"
						style={buttonStyle}
						onClick={() => setIsVisibleConditions(true)}
					>
						{t('conditions')}
					</button>

					{/* Privacy */}
					<button
						className="link-item h8"
						style={buttonStyle}
						onClick={() => setIsVisiblePrivacy(true)}
					>
						{t('policy_notice')}
					</button>

					{/* Cookies */}
					<button
						className="link-item h8"
						style={buttonStyle}
						onClick={() => setIsVisibleCookies(true)}
					>
						{t('cookies')}
					</button>

					{/* Ads */}
					{/* <button
						className="link-item h8"
						style={buttonStyle}
						onClick={() => setAdsModalVisible(true)}
					>
						{t('ads')}
					</button> */}

					{/* Help */}
					<button
						className="link-item h8"
						style={buttonStyle}
						onClick={() => setHelpModalVisible(true)}
					>
						{t('help')}
					</button>
				</Grid>

				<Grid item xs={2} className="social-icons">
					<div
						style={{
							position: 'absolute',
							paddingTop: '1%',
							display: 'flex'
						}}
					>
						<a
							href="https://www.facebook.com/AtlhasTutor"
							target="_blank"
							rel="noreferrer"
						>
							<img src={facebookIcon} className="fb-icon cursor" alt="fb" />
						</a>
						<a
							href="https://x.com/Atlhas_Tutor"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={twitterIcon}
								className="twitter-icon cursor"
								alt="twitter"
							/>
						</a>
						<div>
							<a
								href="https://www.linkedin.com/company/atlhas-tutor/"
								target="_blank"
								rel="noreferrer"
								className="linkedin-icon"
							>
								<LinkedInIcon className="cursor" fontSize="40px" />
							</a>
						</div>
					</div>
				</Grid>
			</Grid>

			<Grid container columns={11} className="login-copyrights">
				<Typography variant="container" className="footer-txt h8">
					&copy; {t('footer_copy_right_text')}
				</Typography>
			</Grid>
			<ModalWrapper
				isVisible={isVisiblePrivacy}
				setIsVisible={setIsVisiblePrivacy}
				file={PrivacyNoticeFile}
				isLoading={isFetchingPrivacyNotice}
				width="50%"
				height="80%"
			/>
			<ModalWrapper
				isVisible={isVisibleConditions}
				setIsVisible={setIsVisibleConditions}
				file={ConditionsFile}
				isLoading={isFetchingConditions}
				width="50%"
				height="80%"
			/>
			<ModalWrapper
				isVisible={isVisibleCookies}
				setIsVisible={setIsVisibleCookies}
				file={CookiesNoticeFile}
				isLoading={isFetchingCookiesNotice}
				width="50%"
				height="80%"
			/>
			{/* <AdsModal
				isVisible={adsModalVisible}
				setIsVisible={setAdsModalVisible}
			/> */}
			<HelpModal
				isVisible={helpModalVisible}
				setIsVisible={setHelpModalVisible}
			/>
		</LoginFooterCon>
	)
}

export default LoginFooter
